import { PogNonCircular } from "@CommonTypes/merchflow/pog/pog";

export const RoutePostMerchflowUploadPsa = "/merchflow/upload-psa";

export type PayloadPostMerchflowUploadPsa = FormData;

export interface PayloadFormPostMerchflowUploadPsa {
  psa_file: File;
  category_id: string; // it can be only text in FormDate
  store_id: string;
  return_also_base_pog?: string;
}

export interface ResponsePostMerchflowUploadPsa {
  storage_url: string;
  upload_id: number;
  uploaded_symbols: number;
  uploaded_lines: number;
  uploaded_stores: number;
  pog?: PogNonCircular;
}
