import { PspRules } from "@CommonTypes/backend/PspRules";
import { Flex, Color, Text, Toggle, HorizontalDivider, Radios } from "src/elements";
import { Transition } from "src/utils";

interface Props {
  pspRules: PspRules;
  setPspRules: (pspRules: PspRules) => void;
  title: string;
  rulesParent: keyof PspRules;
  area: string;
  sectionRule?: string;
  rules: {
    title: string;
    rule: string;
    isDisabled?: boolean;
    isHidden?: boolean;
    options?: string[];
  }[];
}

export const RulesSection = ({
  title,
  pspRules,
  setPspRules,
  rulesParent,
  area,
  rules,
  sectionRule,
}: Props) => {
  const toggleRule = (rule: string, checked: boolean) => {
    const newPspRules = { ...pspRules };

    //@ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    newPspRules[rulesParent][rule] = checked;

    setPspRules(newPspRules);
  };

  return (
    <Flex
      width="100%"
      background={Color.lightGray}
      column
      borderRadius="3px"
      overflow="hidden"
      gridArea={area}
    >
      <Flex
        background={Color.lightGrayHover}
        padding="5px 10px"
        justify="center"
        align="center"
        gap="10px"
      >
        <Text variant="body1">{title}</Text>

        {sectionRule !== undefined && (
          <Toggle
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            isChecked={pspRules[rulesParent][sectionRule]}
            setIsChecked={(checked) => {
              toggleRule(sectionRule, checked);
            }}
          />
        )}
      </Flex>

      <HorizontalDivider color={Color.textDisabled} />

      <Flex column>
        {rules
          .filter((rule) => !rule.isHidden)
          .map((rule, i) => {
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const isChecked = pspRules[rulesParent][rule.rule] === true;

            return (
              <>
                {i > 0 && <HorizontalDivider color={Color.lightGrayHover} />}

                <Flex key={rule.rule} column padding="10px" gap="10px" justify="center">
                  <Text
                    variant="small1"
                    textAlign="center"
                    color={(rule.isDisabled && Color.textDisabled) || Color.textSecondary}
                  >
                    {rule.title}
                  </Text>

                  {rule.options === undefined && (
                    <Flex gap="10px">
                      <Flex
                        opacity={isChecked ? 0 : 1}
                        transition={`opacity ${Transition.fast}`}
                        cursor="default"
                      >
                        <Text
                          variant="body2"
                          color={(rule.isDisabled && Color.textDisabled) || Color.textSecondary}
                        >
                          No
                        </Text>
                      </Flex>

                      <Toggle
                        isDisabled={rule.isDisabled}
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        isChecked={isChecked}
                        setIsChecked={(checked) => {
                          toggleRule(rule.rule, checked);
                        }}
                      />

                      <Flex
                        opacity={isChecked ? 1 : 0}
                        transition={`opacity ${Transition.fast}`}
                        cursor="default"
                      >
                        <Text
                          variant="body2"
                          color={(rule.isDisabled && Color.textDisabled) || Color.textSecondary}
                        >
                          Yes
                        </Text>
                      </Flex>
                    </Flex>
                  )}

                  {rule.options !== undefined && (
                    <Flex justify="center">
                      <Radios
                        options={rule.options.map((option) => ({
                          label:
                            option.substring(0, 1).toUpperCase() +
                            option.substring(1).toLowerCase(),
                          value: option,
                        }))}
                        // @ts-ignore
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        value={pspRules[rulesParent][rule.rule]}
                        setValue={(value) => {
                          const newPspRules = { ...pspRules };
                          // @ts-ignore
                          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                          newPspRules[rulesParent][rule.rule] = value;
                          setPspRules(newPspRules);
                        }}
                        isDisabled={rule.isDisabled}
                      />
                    </Flex>
                  )}
                </Flex>
              </>
            );
          })}
      </Flex>
    </Flex>
  );
};
