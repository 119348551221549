import { useEffect, useState } from "react";
import { useSearchParams } from "src/utils/useSearchParams";

export const useQueryState = <T extends string | number | undefined>(
  key: string,
  defaultValue: T,
) => {
  const { searchParams, setSearchParam } = useSearchParams();

  const [innerValue, setInnerValue] = useState((searchParams[key] as T) || defaultValue);

  const setValue = (value: T) => {
    setSearchParam(key, value);
    setInnerValue(value);
  };

  return [innerValue, setValue] as [T, (value: T) => void];
};
