import { PspRules } from "@CommonTypes/backend/PspRules";
import { RulesSection } from "./components/RulesSection";
import { Grid } from "src/elements";

interface Props {
  pspRules: PspRules;
  setPspRules: (pspRules: PspRules) => void;
}

export const PlanogramRules = ({ pspRules, setPspRules }: Props) => {
  return (
    <Grid
      width="100%"
      gap="20px"
      areas={`
        "fixtures       merchandising"
        "range          merchandising"
        "high-density   high-density "
      `}
    >
      <RulesSection
        pspRules={pspRules}
        setPspRules={setPspRules}
        title="Fixtures"
        rulesParent="fixture"
        area="fixtures"
        rules={[
          {
            title: "Can shelf notches be adjusted in stores for this review?",
            rule: "can_adjust_shelf_notches",
          },
          {
            title: "Can we add or delete shelves to make products fit?",
            rule: "can_add_or_delete_shelves",
          },
          {
            title: "Can we attempt to make shelves the same height as base pog?",
            rule: "shelves_same_height_as_base_pog",
          },
          {
            title: "Can products overlap with shelves vertically?",
            rule: "vertical_overlap",
          },
        ]}
      />

      <RulesSection
        pspRules={pspRules}
        setPspRules={setPspRules}
        title="Merchandising"
        rulesParent="merchandising"
        area="merchandising"
        rules={[
          {
            title:
              "Can we switch between horizontal and vertical blocking to better utilise shelf space?",
            rule: "can_switch_between_horizontal_and_vertical_blocking",
          },
          {
            title: "Can we break trays if a core range product cannot fit otherwise?",
            rule: "can_break_trays_if_core_range_not_fit",
          },
          {
            title: "Can we fill extra space next to cases / trays with loose products?",
            rule: "can_fill_extra_space_next_to_cases_trays_with_loose_products",
          },
          {
            title: "How should loose products be placed with respect to their cases / trays?",
            rule: "how_should_loose_products_be_placed_with_respect_to_their_cases_trays",
            options: ["BEHIND", "FRONT"],
          },
          {
            title: "Can we leverage alternative orientations to fill empty space on a shelf?",
            rule: "leverage_alternative_orientations",
          },
          {
            title: "Allow snaking?",
            rule: "snaking",
          },
          {
            title: "Allow CDT stacking",
            rule: "cdt_stacking",
          },
        ]}
      />

      <RulesSection
        pspRules={pspRules}
        setPspRules={setPspRules}
        title="Range"
        rulesParent="range"
        area="range"
        rules={[
          {
            title: "Can we add non-core range products to fill underutilised shelf space?",
            rule: "can_add_non_core_range_products",
          },
        ]}
      />

      <RulesSection
        pspRules={pspRules}
        setPspRules={setPspRules}
        title="High Density Mode"
        rulesParent="high_density_mode"
        area="high-density"
        sectionRule="enabled"
        rules={[
          {
            title:
              "When variants occupy more than 1 bay, continue ranging products in the next bay from?",
            rule: "snake_on_variant_cross",
            isDisabled: pspRules.high_density_mode.enabled === false,
          },
          {
            title:
              "When there are variant restrictions on shelves, can we round off variants on previous shelves?",
            rule: "variant_contiguous",
            isDisabled: pspRules.high_density_mode.enabled === false,
          },
          {
            title: "Should we place all variants on the mudmap?",
            rule: "place_all_variants",
            isDisabled: pspRules.high_density_mode.enabled === false,
          },
          {
            title: "Mudmap level",
            rule: "mudmap_level",
            isDisabled: pspRules.high_density_mode.enabled === false,
            options: ["CDT1", "CDT2", "ITEM_ID"],
          },
        ]}
      />
    </Grid>
  );
};
