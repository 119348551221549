import React from "react";
import { Link as LinkRouter } from "react-router-dom";
import styled from "styled-components";
import { Color } from "../Color/Color";
import { getCurrentUrl } from "src/utils/history";

const LinkElem = styled(LinkRouter).withConfig({
  shouldForwardProp: (prop) =>
    !["width", "minWidth", "size", "weight", "color", "disabled", "isAbsolute"].includes(prop),
})<Props>`
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  font-size: ${({ size }) => size || "16px"};
  font-weight: ${({ weight }) => weight || "400"};
  color: ${({ color }) => color || Color.primary};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  text-decoration: none;

  ${({ isAbsolute }) =>
    isAbsolute &&
    `
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;`}
`;

interface Props {
  width?: string;
  minWidth?: string;
  size?: string;
  weight?: string;
  color?: string;
  to?: string;
  disabled?: boolean;
  isAbsolute?: boolean;
  inNewTab?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  children?: React.ReactNode;
}

export const Link = ({
  to = "#",
  size,
  weight,
  color,
  disabled,
  isAbsolute,
  inNewTab,
  onClick,
  children,
}: Props) => (
  <LinkElem
    isAbsolute={isAbsolute}
    size={size}
    weight={weight}
    color={color}
    disabled={disabled}
    to={to}
    state={{ previous: getCurrentUrl() }}
    target={(inNewTab && "_blank") || "_self"}
    onClick={onClick}
  >
    <React.Fragment>{children}</React.Fragment>
  </LinkElem>
);
