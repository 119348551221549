import { useSetAtom } from "jotai";
import { useModals } from "src/components/Modals";
import { storePlanogramReviewAtom } from "./atoms";
import { PogNonCircular } from "@CommonTypes/merchflow/pog/pog";

export interface OpenStorePlanogramReviewModal {
  merchflowId: number;
  storePogId: number;
  storePlanograms: {
    templateId: string;
    storeCode: string;
    basePogId: number;
    projectId?: number | null;
    storePog: {
      storePogId: number;
      storePogData: PogNonCircular;
      isApproved: boolean;
    };
  }[];
}

export const useStorePlanogramReviewModal = () => {
  const { openModal } = useModals();
  const setData = useSetAtom(storePlanogramReviewAtom);

  const openStorePlanogramReviewModal = (data: OpenStorePlanogramReviewModal) => {
    setData(data);
    openModal("StorePlanogramReviewModal");
  };

  return { openStorePlanogramReviewModal };
};
