const fileExtensionsToMimeType = {
  psa: "text/psa",
};

export const downloadFile = ({
  name,
  extension,
  data,
}: {
  name: string;
  extension: "psa";
  data: string;
}) => {
  const file = document.createElement("a");
  file.style.display = "none";

  file.setAttribute(
    "href",
    `data:${fileExtensionsToMimeType[extension]};charset=utf-8,${encodeURIComponent(data)}`,
  );
  file.setAttribute("download", `${name}.${extension}`);

  document.body.appendChild(file);
  file.click();
  document.body.removeChild(file);
};

export const downloadFileFromUrl = (url?: string) => {
  if (url === undefined) return;

  const file = document.createElement("a");
  file.style.display = "none";

  file.setAttribute("href", url);

  document.body.appendChild(file);
  file.click();
  document.body.removeChild(file);
};
