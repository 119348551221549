import { FilterConfig } from "@CommonTypes/backend/FilterConfig";
import { FlowReview } from "@CommonTypes/backend/FlowReview";
import { FlowFilterConfigEx } from "@CommonTypes/owa-db/FlowFilterConfigExtended";

export const RoutePutMerchflowsFilterConfig_filterConfigId_ =
  "/merchflows/filter-config/:filterConfigId";

export type PayloadPutMerchflowsFilterConfig_filterConfigId_ = {
  filter_config: Omit<FilterConfig, "stores"> & {
    stores: Omit<FilterConfig["stores"][0], "ffUploadId" | "pmUploadId" | "posUploadId">[];
  };
};

export type ResponsePutMerchflowsFilterConfig_filterConfigId_ = FlowFilterConfigEx;

export const RouteGetMerchflowsFilterConfig_filterConfigId_ =
  RoutePutMerchflowsFilterConfig_filterConfigId_;

export type ResponseGetMerchflowsFilterConfig_filterConfigId_ = FlowReview;
