import { PogNonCircular } from "@CommonTypes/merchflow/pog/pog";

export const RouteGetMerchflowStorePog_storePogId_ = "/merchflow/store/pog/:storePogId";

export interface ResponseGetMerchflowStorePog_storePogId_ {
  id: number;
  pog: PogNonCircular | null;
  status:
    | "DONE"
    | "ERROR"
    | "POGGER_IN_PROGRESS"
    | "POGGERIZE_IN_PROGRESS"
    | "UPDATE_IN_PROGRESS"
    | "NOT_STARTED";
  is_approved: boolean;
  base_pog_id: number;
  store: string;
  filter_config_id: number;
}

export const RoutePutMerchflowStorePog_storePogId_ = RouteGetMerchflowStorePog_storePogId_;

export interface PayloadPutMerchflowStorePog_storePogId_ {
  pog: PogNonCircular;
  save_as_new?: boolean;
}

export interface ResponsePutMerchflowStorePog_storePogId_ {
  store_pog_id: number;
}
