import { Route, Routes } from "react-router-dom";
import { Loader, Toasts, VersionChecker } from "src/components";
import { pages } from "src/utils/pages";
import { GlobalStyle } from "src/utils/globalStyle";

import {
  PageMerchflows_merchflowId_Stores_storeCode,
  PageLogin,
  PageLogout,
  PagePlayground,
  PageMerchflows_merchflowId_Stores_storeCode_Pogs_storePogId_,
  PageMerchflows_merchflowId_BasePog_basePogId_,
  PageMerchflows_merchflowId_S2Templates_templateId_,
  PageHome,
  PageCategories,
  PageProducts,
  PageUsers,
  PageStores,
  PageMerchflowsPogThumbnail,
  PageS0,
  PageS1,
  PageMerchflows_merchflowId_S2_s1UserTriggerId_Templates_templateId_,
  PageMerchflows_merchflowId_S2Solution_s2SolutionId_,
  PageMerchflows_merchflowId_StoresAll,
  PageMerchflows,
} from "./pages";
import PageStoreSummary from "./pages/StoreSummary";
import { PageMerchflowTemplateBasePogs } from "./pages/MerchflowTemplateBasePogs/MerchflowTemplateBasePogs";
import { PageMerchflowTemplates } from "./pages/MerchflowTemplates/MerchflowTemplates";
import Page404 from "./pages/page404";
import { PageIcons } from "./pages/Icons/Icons";
import { IsUnauthorized } from "./components/Authenticator/isUnauthorized";
import { IsAuthorized } from "./components/Authenticator/isAuthorized";
import { Menu } from "./components/Menu/Menu";
import { PageFixtures } from "./pages/Fixtures/Fixtures";
import { PageFreezer } from "./pages/Freezer/Freezer";
import { FeatureFlagProvider } from "./components/FeatureFlagProvider/FeatureFlagProvider";
import { TokenRenewer } from "./components/TokenRenewer/TokenRenewer";
import { env } from "./utils/env";
import { PageMerchflows_ExportPdf_StorePogId } from "./pages/Merchflows_ExportPdf_StorePogId";

export const App = () => {
  return (
    <>
      <GlobalStyle />
      <FeatureFlagProvider>
        <Routes>
          <Route element={<IsUnauthorized />}>
            <Route path={pages.login} element={<PageLogin />} />
          </Route>

          <Route element={<IsAuthorized />}>
            <Route path={pages.logout} element={<PageLogout />} />

            <Route path={pages.home} element={<PageHome />} />

            <Route path={pages.categories} element={<PageCategories />} />

            <Route path={pages.products} element={<PageProducts />} />

            <Route path={pages.fixtures} element={<PageFixtures />} />

            <Route path={pages.playground} element={<PagePlayground />} />

            <Route path={pages.freezer} element={<PageFreezer />} />

            <Route path={pages.icons} element={<PageIcons />} />

            <Route path={pages.merchflows} element={<PageMerchflows />} />

            <Route path={pages.merchflows_merchflowId_S0_s0UserTriggerId_} element={<PageS0 />} />

            <Route path={pages.merchflows_merchflowId_S1_s1UserTriggerId_} element={<PageS1 />} />

            <Route
              path={pages.merchflows_merchflowId_S2_s1UserTriggerId_Templates_templateId_}
              element={<PageMerchflows_merchflowId_S2_s1UserTriggerId_Templates_templateId_ />}
            />

            <Route
              path={pages.merchflows_merchflowId_S2Solution_s2SolutionId_}
              element={<PageMerchflows_merchflowId_S2Solution_s2SolutionId_ />}
            />

            <Route
              path={pages.merchflowsPogThumbnail_basePogId_}
              element={<PageMerchflowsPogThumbnail />}
            />

            <Route
              path={pages.merchflowsPogExportPdf_storePogId_}
              element={<PageMerchflows_ExportPdf_StorePogId />}
            />

            <Route
              path={pages.merchflowsPogExportPdf_basePogId_}
              element={<PageMerchflows_ExportPdf_StorePogId />}
            />

            <Route
              path={pages.merchflowsPogThumbnail_storePogId_}
              element={<PageMerchflowsPogThumbnail />}
            />

            <Route
              path={pages.merchflows_merchflowId_S2Templates_templateId_}
              element={<PageMerchflows_merchflowId_S2Templates_templateId_ />}
            />

            <Route
              path={pages.merchflows_merchflowId_BasePog_basePogId_}
              element={<PageMerchflows_merchflowId_BasePog_basePogId_ />}
            />

            <Route
              path={pages.merchflows_merchflowId_StoresAll}
              element={<PageMerchflows_merchflowId_StoresAll />}
            />

            <Route
              path={pages.merchflows_merchflowId_Stores_storeCode_}
              element={<PageMerchflows_merchflowId_Stores_storeCode />}
            />

            <Route
              path={pages.merchflows_merchflowId_Stores_storeCode_Pogs_storePogId_}
              element={<PageMerchflows_merchflowId_Stores_storeCode_Pogs_storePogId_ />}
            />

            <Route path={pages.stores} element={<PageStores />} />
            <Route path={pages.users} element={<PageUsers />} />
            <Route path={pages.storeSummary} element={<PageStoreSummary />} />

            <Route
              path={pages.merchflowTemplateBasePogs}
              element={<PageMerchflowTemplateBasePogs />}
            />
            <Route path={pages.merchflowTemplates} element={<PageMerchflowTemplates />} />
          </Route>
          <Route path="*" element={<Page404 />} />
        </Routes>

        <Loader />
        <Menu />
        <Toasts />
        <TokenRenewer />
        {env.VITE_ENV !== "local" && <VersionChecker />}
      </FeatureFlagProvider>
    </>
  );
};
