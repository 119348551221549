import html2canvas from "html2canvas";
import { PrimitiveAtom, useAtom } from "jotai";
import { useEffect, useMemo, useRef, useState } from "react";
import { Color, Flex, Scroller } from "src/elements";
import {
  calculatePogCoreRange,
  calculatePogDosMos,
  calculatePogReturnOnSpace,
  calculatePogShelfAlignment,
} from "src/omni-common/utils/pog/pog";
import styled from "styled-components";

import { Bays } from "./components/Bay/Bays";
import { Containers } from "./components/Containers/Containers";
import { FixturesPanel } from "./components/Panel/FixturesPanel";
import { ProductsPanel } from "./components/Panel/ProductsPanel";
import { Shelves } from "./components/Shelf/Shelves";
import { deletedProductAtom, newProductAtom } from "./store/atoms";
import { getOuterBounds, getSnappableFixtures, isPlanogramItemMatching } from "./store/utils";
import { useHighlightsApi } from "src/api/merchflows/highlights";
import { PlanogramContainer as PlanogramContainerType } from "@CommonTypes/merchflow/pog/container";
import { usePOG } from "./store/actions";
import { adapterPogNonCircularToPog, adapterPogToPogNonCircular } from "@CommonAdapters/pog";
import { ResponseMerchFlowApplyFilters } from "@CommonApi/merchandise";
import {
  planogramFixturesModeAtom,
  planogramItemSearcherAtom,
} from "../PlanogramExtensions/store/atoms";
import { Image } from "src/elements/Image/Image";
import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { PogAtom } from "./store/types";
import { useApi } from "src/api";
import { emptyContainersAtom } from "src/pages/merchandise/store/atoms";

const PlanogramOuterContainer = styled(Flex)`
  position: relative;
  display: flex;
  gap: 10px;
  width: 100%;
  flex-grow: 1;
  min-height: 0;
  border-color: ${Color.primary};
  border-style: solid;
  border-radius: 3px;
  overflow: hidden;
  --shelf-color: #001a13;
`;

const PlanogramContainer = styled.div<{ isRenderingAsImage?: boolean }>`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  user-select: none;
  visibility: ${({ isRenderingAsImage }) => isRenderingAsImage && "hidden"};
`;

const PlanogramElement = styled.div<{
  width: number;
  height: number;
  scaleX: number;
  scaleY: number;
}>`
  width: ${({ width, scaleX }) => `calc(${width}px * ${scaleX})`};
  height: ${({ height, scaleY }) => `calc(${height}px * ${scaleY})`};
  background-color: #fcf7f0;
  position: relative;
`;

interface Props {
  isEditable?: boolean;
  isPog?: boolean;
  isRenderingAsImage?: boolean;
  isImageSentToConsole?: boolean;
  isPanelVisible?: boolean;
  isDuplicateDisabled?: boolean;
  merchflowId?: number;
  currentStoreCode?: string;
  isMetricsRecalculated?: boolean;
  setIsModified?: (modified: boolean) => void;
  isWithBorder?: boolean;
  isContainersFullDetails?: boolean;
}

export const Planogram = (
  props: Props & {
    pogAtom: PrimitiveAtom<Pog | null>;
    containersAtom: PrimitiveAtom<PlanogramContainerType[] | null>;
  },
) => {
  const [pog] = useAtom(props.pogAtom);
  const [containers] = useAtom(props.containersAtom);

  return pog ? (
    <PlanogramInner
      {...(props as Props & { pogAtom: PogAtom })}
      containersAtom={
        containers
          ? (props.containersAtom as PrimitiveAtom<PlanogramContainerType[]>)
          : emptyContainersAtom
      }
    ></PlanogramInner>
  ) : null;
};

const PlanogramInner = ({
  pogAtom,
  containersAtom,
  isEditable = false,
  isPog = false,
  isRenderingAsImage,
  isImageSentToConsole,
  isPanelVisible,
  isDuplicateDisabled,
  merchflowId,
  currentStoreCode,
  isMetricsRecalculated = true,
  setIsModified: _setIsModified,
  isWithBorder = false,
  isContainersFullDetails = false,
}: Props & {
  pogAtom: PogAtom;
  containersAtom: PrimitiveAtom<PlanogramContainerType[]>;
}) => {
  const { getMerchflowReviewApi } = useApi();
  const { deleteProduct } = usePOG(pogAtom);
  const { getBaseHighlightsApi } = useHighlightsApi();

  const [pog, setPog] = useAtom(pogAtom);
  const [containers] = useAtom(containersAtom);
  const [fixturesMode] = useAtom(planogramFixturesModeAtom);
  const [search] = useAtom(planogramItemSearcherAtom);
  const [deletedProduct, setDeletedProduct] = useAtom(deletedProductAtom);
  const [newProduct, setNewProduct] = useAtom(newProductAtom);

  const [renderedImageData, setRenderedImageData] = useState<string | null>(null);
  const [scaleX, setScaleX] = useState<number | null>(null);
  const [scaleY, setScaleY] = useState<number | null>(null);
  const [availableStores, setAvailableStores] = useState<
    ResponseMerchFlowApplyFilters["filter_config"] | null
  >(null);

  const refOuterContainer = useRef<HTMLDivElement>(null);
  const refContainer = useRef<HTMLDivElement>(null);
  const refScaleCalculator = useRef<NodeJS.Timeout | null>(null);

  const planogramResizeObserver = new ResizeObserver(() => {
    if (refScaleCalculator.current) {
      clearTimeout(refScaleCalculator.current);
    }

    refScaleCalculator.current = setTimeout(() => {
      calculateScales();
    }, 500);
  });

  useEffect(() => {
    loadStores();
  }, [merchflowId]);

  const loadStores = async () => {
    if (merchflowId) {
      const response = await getMerchflowReviewApi(merchflowId);

      if (response) {
        setAvailableStores(response.filter_config);
      }
    }
  };

  useEffect(() => {
    if (!isRenderingAsImage && isMetricsRecalculated) {
      const coreRange = calculatePogCoreRange(pog);
      const shelfAlignment = calculatePogShelfAlignment(pog);
      const returnOnSpace = calculatePogReturnOnSpace(pog);
      const dosMos = calculatePogDosMos(pog);

      if (
        coreRange !== pog.metrics?.coreRange ||
        shelfAlignment !== pog.metrics?.shelfAlignment ||
        returnOnSpace !== pog.metrics?.returnOnSpace ||
        dosMos !== pog.metrics?.dosMos
      ) {
        setPog((pog) => {
          pog.metrics = {
            ...pog.metrics,
            coreRange,
            shelfAlignment,
            returnOnSpace,
            dosMos,
          };
          return { ...pog };
        });
      }
    }
  }, [pog]);

  useEffect(() => {
    if (refOuterContainer.current) {
      planogramResizeObserver.observe(refOuterContainer.current);
    }
  }, []);

  useEffect(() => {
    if (isRenderingAsImage && !renderedImageData && scaleX && scaleY) {
      triggerRenderingAsImage();
    }
  }, [scaleX, scaleY]);

  // Effect to listen to delete product atom to clear it after it's used.
  useEffect(() => {
    if (deletedProduct) {
      deleteProduct(deletedProduct);
      setDeletedProduct(null);
    }
  }, [deletedProduct]);

  // Effect to add new product into the list of unranged items.
  useEffect(() => {
    if (newProduct) {
      setPog((pog) => {
        const pogNonCircular = adapterPogToPogNonCircular(pog);

        pogNonCircular.unrangedItems.splice(0, 0, {
          ...newProduct,
          shelf: null,
        });

        return adapterPogNonCircularToPog(pogNonCircular);
      });

      setNewProduct(null);
    }
  }, [newProduct]);

  const highlightedItems = useMemo(() => {
    const highlightedItems: string[] = [];

    if (search !== "") {
      for (const bay of pog.planogram.bays) {
        for (const shelf of bay.shelves) {
          for (const item of shelf.items) {
            if (isPlanogramItemMatching(item, search)) {
              highlightedItems.push(item.uniqueId);
            }
          }
        }
      }
    }

    return highlightedItems;
  }, [search]);

  const calculateScales = () => {
    if (!refContainer || !refContainer.current) {
      return;
    }

    const { width: containerWidth, height: containerHeight } =
      refContainer.current.getBoundingClientRect();

    // Compensate horizontal scrollbar size by reducing available height by 6 pixels.
    const scaleY = (containerHeight - 6) / pog.planogram.height;
    const scaleX = containerWidth / pog.planogram.width;
    const minScale = Math.min(scaleX, scaleY);

    setScaleY(
      // Scale to fit entire container for rendering as image.
      (isRenderingAsImage && minScale) ||
        // Scale to fit vertically.
        scaleY,
    );

    setScaleX(
      // Scale to fit entire container for rendering as image.
      (isRenderingAsImage && minScale) ||
        // Scale with same ratio as height to make sure product images are not stretched.
        (isPog && scaleY) ||
        // Scale to fill all available horizontal space.
        scaleX,
    );
  };

  const triggerRenderingAsImage = () => {
    if (refContainer.current) {
      html2canvas(refContainer.current, {
        allowTaint: true,
        useCORS: true,
        backgroundColor: "#fff8ed",
        onclone(_, ele) {
          ele.style.visibility = "visible";
        },
      }).then((canvas) => {
        const image = canvas.toDataURL("image/png", 1.0);
        setRenderedImageData(image);

        // Do not remove this console!
        // This is used by pog thumbnails generation service!
        if (isImageSentToConsole) {
          console.log(image);
        }
      });
    }
  };

  // Load base highlights.
  useEffect(() => {
    if (merchflowId) {
      getBaseHighlightsApi(merchflowId);
    }
  }, [merchflowId]);

  const setIsModified = () => {
    if (_setIsModified) {
      _setIsModified(true);
    }
  };

  const PlanogramRenderer = () => (
    <PlanogramContainer ref={refContainer} isRenderingAsImage={isRenderingAsImage}>
      {scaleX && scaleY && (
        <PlanogramElement
          width={pog.planogram.width}
          height={pog.planogram.height}
          scaleX={scaleX}
          scaleY={scaleY}
        >
          <Bays
            bays={pog.planogram.bays}
            scaleX={scaleX}
            scaleY={scaleY}
            isEditable={isEditable}
            pogAtom={pogAtom}
          />

          <Shelves
            currentStoreCode={currentStoreCode}
            scaleX={scaleX}
            scaleY={scaleY}
            isEditable={isEditable}
            isPog={isPog}
            highlightedItems={highlightedItems}
            pogAtom={pogAtom}
            setIsModified={setIsModified}
            isDuplicateDisabled={isDuplicateDisabled}
            availableStores={availableStores}
          />

          {containers && containers.length > 0 && (
            <Containers
              scaleX={scaleX}
              scaleY={scaleY}
              bounds={getOuterBounds(pog.planogram)}
              snappables={getSnappableFixtures(pog.planogram)}
              isEditable={isEditable}
              isContainersFullDetails={isContainersFullDetails}
              containersAtom={containersAtom}
              pogAtom={pogAtom}
            />
          )}
        </PlanogramElement>
      )}
    </PlanogramContainer>
  );

  return (
    <PlanogramOuterContainer ref={refOuterContainer} borderWidth={isWithBorder ? "1px" : "0"}>
      <Flex width="100%" flexGrow={1} minHeight="0" minWidth="0">
        {isRenderingAsImage && !renderedImageData && <PlanogramRenderer />}

        {!isRenderingAsImage && (
          <Flex flexGrow={1} minHeight="0" minWidth="0">
            <Scroller gutter="auto">
              <PlanogramRenderer />
            </Scroller>
          </Flex>
        )}

        {isPanelVisible && !isRenderingAsImage && (fixturesMode || !isPog) && (
          <FixturesPanel pogAtom={pogAtom} />
        )}

        {isPanelVisible && !isRenderingAsImage && !fixturesMode && isPog && (
          <ProductsPanel
            scaleX={scaleX || 0}
            scaleY={scaleY || 0}
            pogAtom={pogAtom}
            merchflowId={merchflowId}
            currentStoreCode={currentStoreCode}
            availableStores={availableStores}
          />
        )}

        {renderedImageData && <Image src={renderedImageData} />}
      </Flex>
    </PlanogramOuterContainer>
  );
};
