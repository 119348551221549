import React from "react";

import { Flex } from "../Box/Box";
import { Color } from "../Color/Color";
import styled from "styled-components";

const SkeletonElement = styled(Flex)`
  background-color: ${Color.lightGrayHover};
  border-radius: 3px;
  overflow: hidden;
`;

const SkeletonAnimation = styled(Flex)`
  width: 100%;
  height: 100%;
  position: relative;
  left: 0;
  top: 0;
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.07), transparent);
  animation-name: wave;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes wave {
    0% {
      transform: translateX(-150%);
    }

    100% {
      transform: translateX(150%);
    }
  }
`;

interface Props {
  minWidth?: string;
  minHeight?: string;
  width?: string;
  height?: string;
}

export const Skeleton: React.FC<Props> = ({
  minWidth,
  minHeight,
  width = "100%",
  height = "6px",
}) => (
  <SkeletonElement minWidth={minWidth} minHeight={minHeight} width={width} height={height}>
    <SkeletonAnimation />
  </SkeletonElement>
);
