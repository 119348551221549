import { Flex, Color, Text } from "src/elements";
import { useRef } from "react";
import { Transition } from "src/utils";
import styled from "styled-components";
import hexToRgba from "hex-to-rgba";
import { useHoverClickable } from "src/utils/hoverClickable";

const sizeDefault = "14px";
const sizeSmall = "10px";

const RadioContainer = styled(Flex)`
  gap: 5px;
  align-items: center;
`;

const RadioOuterCircle = styled(Flex)<{ size?: "default" | "small" }>`
  min-width: ${({ size }) => (size === "small" ? sizeSmall : sizeDefault)};
  min-height: ${({ size }) => (size === "small" ? sizeSmall : sizeDefault)};
  width: ${({ size }) => (size === "small" ? sizeSmall : sizeDefault)};
  height: ${({ size }) => (size === "small" ? sizeSmall : sizeDefault)};
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`;

const RadioInnerCircle = styled(Flex)<{ isVisible: boolean }>`
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 50%;
  transform: ${({ isVisible }) => `scale(${isVisible ? "100%" : "0%"})`};
  transform-origin: center;
  transition: transform ${Transition.fast};
`;

interface Props<T> {
  label?: string;
  value: T;
  setIsChecked: (value: T) => void;
  size?: "default" | "small";
  isChecked?: boolean;
  isDisabled?: boolean;
  isUnselectable?: boolean;
  variant?: "body2" | "small2";
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Radio = <T extends any>({
  label,
  value,
  size,
  variant,
  isDisabled,
  isUnselectable,
  isChecked,
  setIsChecked,
}: Props<T>) => {
  const refRadio = useRef<HTMLDivElement>(null);
  const { isHovered, isActive } = useHoverClickable(refRadio);

  const radioColor =
    (isDisabled && Color.textDisabled) ||
    (isActive && Color.primaryActive) ||
    (isChecked && Color.primary) ||
    (isHovered && hexToRgba(Color.primary, 0.5)) ||
    Color.spaceGray;

  const textColor =
    (isDisabled && Color.textDisabled) || (isChecked && Color.textMain) || Color.textSecondary;

  const textVariant = size === "small" ? "caption1" : "body2";

  const onClick = () => {
    if (isChecked && isUnselectable) {
      return setIsChecked(null as T);
    }

    if (!isChecked) {
      return setIsChecked(value);
    }
  };

  return (
    <RadioContainer ref={refRadio} onClick={onClick} isDisabled={isDisabled}>
      <RadioOuterCircle size={size} borderColor={radioColor}>
        <RadioInnerCircle
          background={radioColor}
          isVisible={isChecked || (!isDisabled && isHovered)}
        />
      </RadioOuterCircle>

      {label && (
        <Text variant={variant || textVariant} color={textColor}>
          {label}
        </Text>
      )}
    </RadioContainer>
  );
};
