import { useSetAtom } from "jotai";
import { Modals } from "src/modals";
import { confirmModalDataAtom } from "src/modals/ModalConfirm/store/atoms";
import { ConfirmModalProps } from "src/modals/ModalConfirm/store/types";

import { modalsAtom } from "./atoms";

export const useModals = () => {
  const setModals = useSetAtom(modalsAtom);
  const setConfirmModalData = useSetAtom(confirmModalDataAtom);

  const openModal = (modal: Modals) => {
    setModals((modals) => [...modals, modal]);
  };

  const closeModal = (modal: Modals) => {
    document.querySelector(`[data-modal=${modal}]`)?.setAttribute("data-close", "");

    // Timeout to hide modal with transition before removing.
    setTimeout(() => {
      setModals((modals) => modals.filter((m) => m !== modal));
      document.querySelector(`[data-modal=${modal}]`)?.removeAttribute("data-close");
      document.querySelector(`[data-modal=${modal}]`)?.setAttribute("data-open", "");
    }, 300);
  };

  const openConfirmModal = (data: ConfirmModalProps) => {
    setConfirmModalData(data);
    openModal("ModalConfirm");
  };

  return { openModal, closeModal, openConfirmModal };
};
