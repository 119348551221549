import { PlanogramBay, Pog } from "@CommonTypes/merchflow/pog/pog";
import { getPlanogramItemCases, getPlanogramItemUnitsOnShelf } from "@CommonUtils/pog/item";
import { atom } from "jotai";
import { noop } from "lodash";
import moment from "moment";
import { Shelves } from "src/components/Planogram/components/Shelf/Shelves";
import { Box, Color, Flex, SubgridTable, Text } from "src/elements";
import { SubgridCell } from "src/elements/SubgridTable/components/SubgridCell";
import { SubgridRow } from "src/elements/SubgridTable/components/SubgridRow";
import { SubgridColumn } from "src/elements/SubgridTable/store/types";
import styled from "styled-components";
import { A4Width, HeaderHeight } from "./constant";
import { StoreDetail } from "./Merchflows_ExportPdf_StorePogId";
import { PageHeader } from "./PageHeader";
import { A4Page, A4PageItem } from "./types";

const CustomRow = styled(SubgridRow)`
  background-color: ${Color.greenSmoke};
`;

const PlanogramFooter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  border-top: 1px solid black;
  display: flex;
  justify-content: space-between;
`;
export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid black;
  flex-grow: 1;
  user-select: none;
`;

const columns: SubgridColumn<A4PageItem>[] = [
  { id: "no", header: "NO", width: "24px" },
  { id: "id", header: "ID" },
  { id: "name", header: "NAME", width: "350px" },
  { id: "uos", header: "UOS" },
  { id: "newItemFlag", header: "NEW", width: "36px" },
  { id: "facings", header: "FACINGS" },
  { id: "cases", header: "CASES" },
];

type PlanogramPageRendererProps = {
  storeDetail: StoreDetail | null;
  bay: PlanogramBay;
  totalSection: number;
  page: A4Page;
  section: number;
  slide: number;
  scaleX: number;
  scaleY: number;
  pog: Pog;
};
export const PlanogramPageRenderer = ({
  storeDetail,
  bay,
  page,
  section,
  slide,
  scaleX,
  scaleY,
  pog,
}: PlanogramPageRendererProps) => {
  const newBay = { ...bay, xLeft: 0, xRight: bay.xRight - bay.xLeft };
  newBay.shelves = newBay.shelves.map((shelf) => {
    return {
      ...shelf,
      bay: newBay,
    };
  });

  return (
    <PageContainer>
      {storeDetail && <PageHeader storeDetail={storeDetail} />}
      <Flex
        justify="between"
        height={`calc(${A4Width} - ${HeaderHeight})`}
        borderBottom="1px solid black"
      >
        <Flex
          position="relative"
          justify="center"
          borderRight="1px solid black"
          width="40%"
          overflow="hidden"
        >
          <Box
            position="relative"
            width={`${(newBay.xRight - newBay.xLeft) * (scaleX ?? 1)}px`}
            height="calc(100% - 48px)"
          >
            {scaleX && scaleY && (
              <Shelves
                scaleX={scaleX}
                scaleY={scaleY}
                isEditable={false}
                isPog={true}
                highlightedItems={[]}
                pogAtom={atom({
                  ...pog,
                  planogram: {
                    ...pog.planogram,
                    bays: [newBay],
                  },
                })}
                setIsModified={noop}
                isDuplicateDisabled={false}
                availableStores={null}
                showShelfName={true}
              />
            )}
          </Box>

          <PlanogramFooter>
            <Box borderRight="1px solid black" padding="8px" flexGrow={1}>
              Date: {moment(moment.now()).format("ddd MMM DD YYYY")}
            </Box>
            <Box borderRight="1px solid black" padding="8px" flexGrow={1}>
              Section: {section} of {pog.planogram.bays.length}
            </Box>
            <Box padding="8px" flexGrow={1}>
              Slide {slide}
            </Box>
          </PlanogramFooter>
        </Flex>
        <Box width="60%">
          <SubgridTable
            columns={columns}
            data={page}
            rowRenderer={(row) => {
              if (row.type === "ITEM") {
                const uos = getPlanogramItemUnitsOnShelf(row);
                const cases = getPlanogramItemCases(row);
                return (
                  <CustomRow>
                    <SubgridCell>{row.no}</SubgridCell>
                    <SubgridCell>{row.id}</SubgridCell>
                    <SubgridCell>{row.name.slice(0, 45)}</SubgridCell>
                    <SubgridCell>{uos ? uos : "-"}</SubgridCell>
                    <SubgridCell>
                      <Text color={Color.red}>{row.newItemFlag ? "new" : ""}</Text>
                    </SubgridCell>
                    <SubgridCell>{row.facings}</SubgridCell>
                    <SubgridCell>{cases ? cases : "-"}</SubgridCell>
                  </CustomRow>
                );
              }

              return (
                <div
                  style={{
                    display: "block",
                    fontWeight: 600,
                    fontSize: "12px",
                    backgroundColor: Color.greenSmokeActive,
                  }}
                >
                  Shelf: {row.shelfName}
                </div>
              );
            }}
          />
        </Box>
      </Flex>
    </PageContainer>
  );
};
