import { Flex, Icon } from "src/elements";
import styled from "styled-components";
import { StoreDetail } from "./Merchflows_ExportPdf_StorePogId";

export const PageHeaderContainer = styled.div`
  height: 80px;
  border-bottom: 1px solid black;
  display: flex;
  width: 100%;
  & > * {
    border-right: 1px solid black;
  }
`;
interface Props {
  storeDetail: StoreDetail;
}

export const PageHeader = ({ storeDetail }: Props) => {
  return (
    <PageHeaderContainer>
      <Flex width="300px" justify="center" align="center">
        <Icon name="logo" size={80} />
      </Flex>
      <Flex direction="column" width="600px">
        <Flex height="50%" align="center" justify="center">
          {storeDetail.store}
        </Flex>
        <Flex borderTop="1px solid black" height="50%" align="center" justify="center">
          {storeDetail.retailer_category_code} - {storeDetail.category_code}
        </Flex>
      </Flex>
    </PageHeaderContainer>
  );
};
