import React, { useRef, useState } from "react";

import { Input } from "../Input/Input";
import { IconType } from "../Icon/Icon";
import { useDebounce } from "src/hooks";

interface Props {
  placeholder?: string;
  width?: string;
  isBorderless?: boolean;
  isFocusedAutomatically?: boolean;
  rightIcon?: { name: IconType; onClick?: () => void } | false;
  onSearch: (search: string) => void;
  refSearch?: React.RefObject<HTMLInputElement>;
}

export const SearchUncontrolled = ({
  placeholder = "Search",
  width,
  isBorderless,
  isFocusedAutomatically,
  rightIcon,
  onSearch,
  refSearch: refSearchForwarded,
}: Props) => {
  const refSearch = useRef<HTMLInputElement>(null);

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  React.useEffect(() => {
    onSearch(debouncedSearch);
  }, [debouncedSearch]);

  const getRef = () => {
    return refSearchForwarded || refSearch;
  };

  const clearSearch = () => {
    setSearch("");
    getRef().current?.focus();
  };

  return (
    <Input
      value={search}
      setValue={setSearch}
      placeholder={`${placeholder}`.replaceAll(".", "") + "..."}
      width={width}
      isBorderless={isBorderless}
      isFocusedAutomatically={isFocusedAutomatically}
      leftIcon={{ name: "search" }}
      rightIcon={(search && { name: "clear", onClick: clearSearch }) || rightIcon}
      refInput={refSearchForwarded || refSearch}
    />
  );
};
