import { ResponseGetAllStoreSummary } from "@CommonApi/store-summary";
import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { PrimitiveAtom, useAtomValue } from "jotai";
import { chunk } from "lodash";
import { useEffect, useState } from "react";
import { CellElement } from "src/elements/SubgridTable/components/SubgridCell";
import styled from "styled-components";
import { PogAtom } from "../../components/Planogram/store/types";
import { A4Height, A4Width } from "./constant";
import { NotchPage } from "./NotchPage";
import { PageHeader } from "./PageHeader";
import { PageContainer, PlanogramPageRenderer } from "./PlanogramPageRenderer";
import { A4Page, BayWithA4Pages } from "./types";
import { StoreDetail } from "./Merchflows_ExportPdf_StorePogId";

const View = styled.div`
  --shelf-color: gray;
  width: ${A4Height};
  height: ${A4Width};
  ${CellElement} {
    padding: 2px;
  }
`;

interface Props {
  currentStoreCode?: string;
  pogAtom: PrimitiveAtom<Pog>;
  storeDetail: StoreDetail | null;
}

export const PdfPlanogramPage = (props: Props) => {
  const pog = useAtomValue(props.pogAtom);

  return pog ? (
    <PlanogramInner {...(props as Props & { pogAtom: PogAtom })}></PlanogramInner>
  ) : null;
};

const PlanogramInner = ({
  pogAtom,
  storeDetail,
}: Props & {
  pogAtom: PogAtom;
}) => {
  const pog = useAtomValue(pogAtom);

  const [scaleX, setScaleX] = useState<number | null>(null);
  const [scaleY, setScaleY] = useState<number | null>(null);

  const calculateScales = () => {
    // Compensate horizontal scrollbar size by reducing available height by 6 pixels.
    const scaleY = (794 * 0.8) / pog.planogram.height;
    setScaleY(scaleY);
    setScaleX(scaleY);
  };
  useEffect(() => {
    calculateScales();
  }, []);

  const processedBay = pog.planogram.bays.reduce((total, currentBay) => {
    const bayItems: A4Page = [];
    let itemNo = 0;
    for (const shelf of currentBay.shelves) {
      bayItems.push({
        shelfName: `${currentBay.bayNo}.${shelf.shelfNo}`,
        type: "SHELF",
      });
      shelf.items.forEach((item) => {
        bayItems.push({
          ...item,
          shelfName: `${currentBay.bayNo}.${shelf.shelfNo}`,
          type: "ITEM" as "ITEM" | "SHELF",
          no: ++itemNo,
        });
      });
    }

    const bayItemsToChunk = chunk(bayItems, 30);
    const pages = bayItemsToChunk.map((page) => {
      const tempPage = [...page];

      const firstItem = tempPage[0];
      // Add first shelf if it's not there
      if (firstItem.type !== "SHELF") {
        tempPage.unshift({
          shelfName: firstItem.shelfName,
          type: "SHELF" as const,
        });
      }

      // Remove last shelf if it's empty
      const lastItem = tempPage[page.length - 1];
      if (lastItem.type === "SHELF") {
        tempPage.pop();
      }

      return tempPage;
    });

    total.push({
      bay: currentBay,
      pages,
    });
    return total;
  }, [] as BayWithA4Pages[]);

  return (
    <View>
      {scaleX &&
        scaleY &&
        processedBay.map((bayWithPages) =>
          bayWithPages.pages.map((page, index) => (
            <PlanogramPageRenderer
              key={index}
              storeDetail={storeDetail}
              section={bayWithPages.bay.bayNo}
              slide={index + 1}
              totalSection={pog.planogram.bays.length}
              bay={bayWithPages.bay}
              page={page}
              scaleX={scaleX}
              scaleY={scaleY}
              pog={pog}
            />
          )),
        )}
      <PageContainer>
        {storeDetail && <PageHeader storeDetail={storeDetail} />}
        <NotchPage pog={pog} />
      </PageContainer>
    </View>
  );
};
