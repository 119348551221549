import { adapterPogNonCircularToPog } from "@CommonAdapters/pog";
import { OneStoreViewThumbnail } from "@CommonTypes/merchflow/OneStoreView";
import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { PogNonCircular } from "@CommonTypes/merchflow/pog/pog";
import hexToRgba from "hex-to-rgba";
import { atom } from "jotai";
import { useMemo, useRef } from "react";
import { Planogram, Tooltip } from "src/components";
import { Icon, Color, Flex, ProgressBar, VerticalDivider, Text, Selection } from "src/elements";
import { emptyContainersAtom } from "src/pages/merchandise/store/atoms";
import { useHoverClickable } from "src/utils/hoverClickable";
import styled from "styled-components";

export const pogOverviewWidth = "422px";
export const pogOverviewHeight = "222px";
const sidebarWidth = "44px";

export enum OneTemplateViewStatus {
  MULTIPLE_BASE_POG_FAVOURITE = "MULTIPLE_BASE_POG_FAVOURITE",
}

type Status = "NONE" | "BORDER" | "IN_PROGRESS" | "SUCCESS" | "WARNING" | "ERROR";

const PogOverviewElement = styled(Flex)<{ width?: string; height?: string }>`
  position: relative;
  width: ${({ width }) => width || pogOverviewWidth};
  height: ${({ height }) => height || pogOverviewHeight};
  border-radius: 3px;
`;

const Badge = styled(Flex)<{ status: Status }>`
  position: absolute;
  top: -10px;
  left: -10px;
  width: 24px;
  height: 24px;
  background-color: ${({ status }) =>
    (status === "WARNING" && Color.yellowActive) ||
    (status === "ERROR" && Color.redActive) ||
    (status === "SUCCESS" && Color.greenSmoke) ||
    Color.white};
  border: 1px solid
    ${({ status }) =>
      (status === "WARNING" && Color.yellowActive) ||
      (status === "ERROR" && Color.redActive) ||
      (status === "SUCCESS" && Color.primaryActive) ||
      Color.spaceGray};
  color: ${({ status }) =>
    (status === "WARNING" && Color.white) ||
    (status === "ERROR" && Color.white) ||
    (status === "SUCCESS" && Color.primary) ||
    Color.textSecondary};
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const StatusOverlay = styled(Flex)<{ status: Status }>`
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 60px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: ${({ status }) =>
    (status === "WARNING" && Color.yellow) ||
    (status === "ERROR" && Color.red) ||
    (status === "SUCCESS" && Color.primary) ||
    (status === "BORDER" && Color.textMain) ||
    Color.lightGrayHover};
  background-color: ${({ status }) =>
    (status === "WARNING" && hexToRgba(Color.yellow, 0.2)) ||
    (status === "ERROR" && hexToRgba(Color.red, 0.2)) ||
    (status === "SUCCESS" && hexToRgba(Color.primary, 0.2)) ||
    "transparent"};
  pointer-events: none;

  > span {
    width: 100%;
  }
`;

const Label = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  border-radius: 0 0 3px 0;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(1px);
`;

const Thumbnail = styled(Flex)`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: bottom left;
`;

interface Props<T> {
  selectionId?: T;
  pog?: PogNonCircular | false;
  containers?: PlanogramContainer[] | false;
  status?: Status;
  statusMessage?: string | false;
  thumbnail?: OneStoreViewThumbnail;
  label?: string;
  badge?: string | number | false;
  warning?: string | false;
  isAllSelected?: boolean;
  selection?: T[];
  setSelection?: (selection: T[]) => void;
  width?: string;
  height?: string;
  isShowMetrics?: boolean;
  isRenderingAsImage?: boolean;
}

export const PogOverview = <T extends number | string>({
  selectionId,
  pog,
  containers,
  status = "NONE",
  statusMessage,
  thumbnail,
  label,
  badge,
  warning,
  isAllSelected,
  selection,
  setSelection,
  width,
  height,
  isShowMetrics = true,
  isRenderingAsImage = true,
}: Props<T>) => {
  const refOverview = useRef<HTMLDivElement>(null);
  const { isHovered } = useHoverClickable(refOverview);

  const pogAtom = useMemo(
    () => (pog ? atom(adapterPogNonCircularToPog(pog)) : null),
    [pog, status],
  );

  const containersAtom = useMemo(() => (containers ? atom(containers) : null), [containers]);

  return (
    <PogOverviewElement width={width} height={height} ref={refOverview}>
      {badge && (
        <Badge status={status}>
          <Text variant="caption1" color="inherit" bold>
            {badge}
          </Text>
        </Badge>
      )}

      <Flex background={Color.planogram} flexGrow>
        {pogAtom && (thumbnail === undefined || thumbnail.status === "ERROR") && (
          <Planogram
            key={pogAtom.toString()}
            pogAtom={pogAtom}
            containersAtom={containersAtom || emptyContainersAtom}
            isPog={!containers}
            isRenderingAsImage={isRenderingAsImage}
          />
        )}

        {thumbnail?.status === "DONE" && <Thumbnail background={`url(${thumbnail.url})`} />}
      </Flex>

      <VerticalDivider color={Color.lightGrayHover} />

      {isShowMetrics && (
        <Flex width={sidebarWidth} column gap="5px" align="center" padding="5px">
          <ProgressBar percent={pog ? pog.metrics?.returnOnSpace : undefined} height="7px" />

          <ProgressBar percent={pog ? pog.metrics?.coreRange : undefined} height="7px" />

          <ProgressBar percent={pog ? pog.metrics?.shelfAlignment : undefined} height="7px" />

          <ProgressBar percent={pog ? pog.metrics?.dosMos : undefined} height="7px" />
        </Flex>
      )}

      <StatusOverlay status={status}>
        {status === "IN_PROGRESS" && <Icon name="spinner" size={32} />}

        {status === "WARNING" && <Icon name="alert" color={Color.yellow} size={32} />}

        {status === "ERROR" && <Icon name="alert" color={Color.red} size={32} />}

        {statusMessage && (
          <Text
            variant="h5"
            color={
              (status === "ERROR" && Color.red) ||
              (status === "WARNING" && Color.yellow) ||
              Color.spaceGray
            }
            textAlign="center"
            textOverflow="ellipsis"
            lineClamp={6}
          >
            {statusMessage}
          </Text>
        )}
      </StatusOverlay>

      {label && isHovered && (
        <Label>
          <Text variant="small1" color={Color.white}>
            {label}
          </Text>
        </Label>
      )}

      {warning && (
        <Flex
          position="absolute"
          width={sidebarWidth}
          bottom="0"
          right="0"
          padding="10px"
          justify="center"
          zIndex={1}
        >
          <Icon name="alert" color={Color.yellow} size={24} />
          <Tooltip>{warning}</Tooltip>
        </Flex>
      )}

      {selectionId !== undefined && selection !== undefined && setSelection !== undefined && (
        <Selection
          id={selectionId}
          isAllSelected={isAllSelected || false}
          selection={selection}
          setSelection={setSelection}
        />
      )}
    </PogOverviewElement>
  );
};
