import { useSearchParams as useReactRouterSearchParams } from "react-router-dom";

export const useSearchParams = () => {
  const [_searchParams, setSearchParams] = useReactRouterSearchParams();
  const searchParams = Object.fromEntries(_searchParams.entries());

  const setSearchParam = (key: string, value: string | number | undefined) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const newSearchParams = JSON.parse(JSON.stringify(searchParams));

    if (value === undefined || value === "") {
      delete newSearchParams[key];
    } else {
      newSearchParams[key] = value;
    }

    setSearchParams(newSearchParams);
  };

  const appendSearchParams = (append: { [key: string]: string | number | undefined }) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const newSearchParams = JSON.parse(JSON.stringify(searchParams));

    for (const key in append) {
      if (append[key] === undefined) {
        delete newSearchParams[key];
      } else {
        newSearchParams[key] = append[key];
      }
    }

    setSearchParams(newSearchParams);
  };

  return {
    searchParams,
    setSearchParam,
    setSearchParams,
    appendSearchParams,
  };
};
