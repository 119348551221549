import { useSetAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Page, PageStatus, Tooltip, ViewAsStoreTemplate } from "src/components";
import {
  Button,
  Checkbox,
  Color,
  Flex,
  HorizontalDivider,
  Icon,
  Pagination,
  Scroller,
  Select,
  SubgridTable,
  Text,
} from "src/elements";
import styled from "styled-components";

import { MerchflowHeader } from "../../components/merchflows/MerchflowHeader/MerchflowHeader";

import { StoresGridLegend, StoresListLegend } from "../../components/merchflows/Legends";
import { highlightsModeAtom } from "src/components/Planogram/store/atoms";
import { ResponseGetMerchflows_merchflowId_Details } from "@CommonApi/merchflows/_merchflowId_/details";
import { useApi } from "src/api";

import { useAsyncState } from "src/hooks/useAsyncState";
import { getStoresAllTableColumns } from "./store/columns";
import { merchflows_merchflowId_StoresAllHistoryAtom } from "./store/atoms";
import { useStorePlanogramReviewModal } from "src/modals/StorePlanogramReview/store/hooks";
import { useBasePlanogramReviewModal } from "src/modals/BasePlanogramReview/store/hooks";
import { useSearchParams } from "src/utils/useSearchParams";
import { usePaginationPage } from "src/elements/Pagination";
import { useQueryState } from "src/hooks/queryState";
import { ResponseMerchFlowApplyFilters } from "@CommonApi/merchandise";
import { StorePogsExporter } from "src/components/StorePogsExporter/StorePogsExporter";
import { SelectStorePogTrigger } from "./components/SelectStorePogTrigger";
import { useViolationsReportModal } from "src/modals/ViolationsReport/store/hooks";
import { usePowerBiReportModal } from "src/modals/PowerBiReportModal/hooks";
import { useInlineLoaders } from "src/components/InlineLoader";
import { RoutePutMerchflows_MerchflowId_StoresApprovals } from "@CommonApi/merchflows/_merchflowId_/stores/approvals";
import { PogOverview } from "../Merchflows_merchflowId_Stores_storeCode/components/PogOverview";
import { isPogHavingRangeRecommendations } from "@CommonUtils/pog/pog";
import { uuid as newUuid } from "src/utils";
import { ResponseGetMerchflow_merchflowId_FlowUpdate_storePogTriggerId_ } from "@CommonApi/merchflow/_merchflowId_/flow-update/_store-pog-trigger-id_";
import { ResponseGetMerchflows_merchflowId_StoresAll } from "@CommonApi/merchflows/_merchflowId_/stores/all";

const PogsContainer = styled(Flex)`
  min-height: 0;
  flex-grow: 1;
  flex-direction: column;
  border: 1px solid ${Color.lightGrayHover};
  border-radius: 3px;
`;

export const PageMerchflows_merchflowId_StoresAll = () => {
  const { merchflowId: _merchflowId } = useParams<{
    merchflowId: string;
  }>();
  const { searchParams, appendSearchParams } = useSearchParams();
  const merchflowId = Number(_merchflowId);

  const {
    getMerchflowDetailsApi,
    getMerchflowStoresAllApi,
    getMerchflowReviewApi,
    getStorePropStatusApi,
    putMerchflowApprovalsApi,
  } = useApi();
  const { openStorePlanogramReviewModal } = useStorePlanogramReviewModal();
  const { openBasePlanogramReviewModal } = useBasePlanogramReviewModal();
  const { openViolationsReportModal } = useViolationsReportModal();
  const { openAllStoresReport, openCategoryRangeReviewReport } = usePowerBiReportModal();
  const { isInlineLoading } = useInlineLoaders();

  const refLoadStoresUuid = useRef<string | null>(null);
  const refLoadStoresTimeout = useRef<NodeJS.Timeout | null>(null);
  const refLoadStorePropStatusUuid = useRef<string | null>(null);
  const refLoadStorePropStatusTimeout = useRef<NodeJS.Timeout | null>(null);

  const [storePropStatus, setStorePropStatus] =
    useAsyncState<ResponseGetMerchflow_merchflowId_FlowUpdate_storePogTriggerId_>();
  const [data, setData] = useAsyncState<ResponseGetMerchflows_merchflowId_StoresAll>();
  const [details, setDetails] = useAsyncState<ResponseGetMerchflows_merchflowId_Details>();
  const [merchflowFilter, setMerchflowFilter] = useAsyncState<ResponseMerchFlowApplyFilters>();
  const [page, setPage] = usePaginationPage();
  const [selection, setSelection] = useState<number[]>([]);
  const [isAllSelected, _setIsAllSelected] = useState(false);
  const [viewMode] = useQueryState<"list" | "grid">("view", "list");
  const [isManualDataLoad, setIsManualDataLoad] = useState(false);
  const setStoresAllHistory = useSetAtom(merchflows_merchflowId_StoresAllHistoryAtom);
  const setHighlightsMode = useSetAtom(highlightsModeAtom);

  const isLoading = data === undefined || isManualDataLoad;
  const storesPerPage = viewMode === "list" ? 100 : 8;
  const isViolations = details?.is_violated === true;
  const isApprovalsLoading = isInlineLoading(RoutePutMerchflows_MerchflowId_StoresApprovals);
  const storePogTriggerId: number | undefined = searchParams["store_pog_trigger_id"]
    ? Number(searchParams["store_pog_trigger_id"])
    : undefined;

  useEffect(() => {
    setDetails(getMerchflowDetailsApi(merchflowId));
    setHighlightsMode(false);
    setMerchflowFilter(getMerchflowReviewApi(merchflowId));

    return () => {
      if (refLoadStoresTimeout.current) clearTimeout(refLoadStoresTimeout.current);
      refLoadStoresUuid.current = null;
    };
  }, []);

  useEffect(() => {
    setIsManualDataLoad(true);
    loadStores();
    loadStorePropStatus();
  }, [viewMode, page]);

  const loadStorePropStatus = async (uuid?: string) => {
    if (!storePogTriggerId) return;

    if (!uuid) {
      refLoadStorePropStatusUuid.current = newUuid();
      uuid = refLoadStorePropStatusUuid.current;
      if (refLoadStorePropStatusTimeout.current) {
        clearTimeout(refLoadStorePropStatusTimeout.current);
      }
    }

    const response = await getStorePropStatusApi(merchflowId, storePogTriggerId);
    setStorePropStatus(response);

    if (response?.in_progress_count !== undefined && response.in_progress_count > 0) {
      refLoadStorePropStatusTimeout.current = setTimeout(() => {
        loadStorePropStatus(uuid);
      }, 5000);
    }
  };

  const loadStores = async (uuid?: string) => {
    if (!uuid) {
      refLoadStoresUuid.current = newUuid();
      uuid = refLoadStoresUuid.current;
      if (refLoadStoresTimeout.current) {
        clearTimeout(refLoadStoresTimeout.current);
      }
    }

    const response = await getMerchflowStoresAllApi(merchflowId, {
      page,
      size: storesPerPage,
      store_pog_trigger_id: storePogTriggerId,
    });
    if (refLoadStoresUuid.current !== uuid) {
      return;
    }

    setData(response);
    setIsManualDataLoad(false);

    if (
      response?.data.find(
        (store) =>
          store.store_pog.status === "NOT_STARTED" ||
          store.store_pog.status === "POGGERIZE_IN_PROGRESS" ||
          store.store_pog.status === "POGGER_IN_PROGRESS" ||
          store.store_pog.status === "UPDATE_IN_PROGRESS",
      )
    ) {
      refLoadStoresTimeout.current = setTimeout(() => {
        loadStores(uuid);
      }, 20_000);
    }
  };

  const setViewMode = (view: "list" | "grid") => {
    setData(undefined);
    appendSearchParams({
      view,
      page: 1,
    });
  };

  const setIsAllSelected = (isAllSelected: boolean) => {
    _setIsAllSelected(isAllSelected);
    setSelection([]);
  };

  const approveSelectedStorePogs = async () => {
    const response = await putMerchflowApprovalsApi(
      merchflowId,
      isAllSelected
        ? {
            approveAllExclude: selection.map((storePogId) => ({
              store_pog_id: storePogId,
            })),
          }
        : {
            approved: selection.map((storePogId) => ({
              store_pog_id: storePogId,
            })),
          },
    );

    if (response) {
      setData(undefined);
      setIsAllSelected(false);
      setSelection([]);
      loadStores();
    }
  };

  const unapproveSelectedStorePogs = async () => {
    const response = await putMerchflowApprovalsApi(
      merchflowId,
      isAllSelected
        ? {
            unapproveAllExclude: selection.map((storePogId) => ({
              store_pog_id: storePogId,
            })),
          }
        : {
            unapproved: selection.map((storePogId) => ({
              store_pog_id: storePogId,
            })),
          },
    );

    if (response) {
      setData(undefined);
      setIsAllSelected(false);
      setSelection([]);
      loadStores();
    }
  };

  const openStoreIntegrityReport = () => {
    if (!merchflowFilter?.id || !details?.latest_base_pog_id) {
      return;
    }

    openAllStoresReport({
      filterConfigId: merchflowFilter.id,
      basePogId: details.latest_base_pog_id,
    });
  };

  const openAllStoresCategoryRangeReviewReport = () => {
    if (data?.project_id) {
      openCategoryRangeReviewReport(data.project_id);
    }
  };

  return (
    <Page>
      <MerchflowHeader merchflowId={merchflowId} />

      <Flex column borderColor={Color.lightGrayHover} borderRadius="3px">
        <Flex gap="10px" justify="between" align="center" padding="10px">
          <Flex gap="10px">
            {storePropStatus?.type === "FLOW_UPDATE" && (
              <Flex gap="5px" align="center">
                <Icon
                  name={(storePropStatus.in_progress_count === 0 && "checkmarkCircle") || "spinner"}
                  size={14}
                />

                <Text color={Color.primaryActive}>
                  {storePropStatus.in_progress_count === 0
                    ? "Store propagation completed"
                    : "Store propagation is running..."}
                </Text>

                <Tooltip textAlign="left">
                  <Flex column>
                    {storePropStatus.in_progress_count > 0 && (
                      <div>
                        In progress: <b>{storePropStatus.in_progress_count}</b>
                      </div>
                    )}

                    <div>
                      Fully updated: <b>{storePropStatus.success_result_count}</b>
                    </div>

                    <div>
                      Partially updated: <b>{storePropStatus.partial_result_count}</b>
                    </div>

                    <div>
                      Failed: <b>{storePropStatus.fail_result_count}</b>
                    </div>
                  </Flex>
                </Tooltip>
              </Flex>
            )}
          </Flex>

          <Flex gap="10px">
            <Button
              variant={isViolations ? "default" : "inverted"}
              color="yellow"
              onClick={() => openViolationsReportModal(merchflowId)}
              isDisabled={data === undefined || isViolations !== true}
            >
              Violations
            </Button>

            <Button
              color="greenSmoke"
              isDisabled={data === undefined}
              dropdown={[
                {
                  label: "Store Integrity Report",
                  onClick: openStoreIntegrityReport,
                },
                {
                  label: "Category Range Review Report",
                  isDisabled: !data?.project_id,
                  onClick: openAllStoresCategoryRangeReviewReport,
                },
              ]}
            >
              Reports
            </Button>

            <Button
              color="greenSmoke"
              isLoading={isApprovalsLoading}
              isDisabled={data === undefined}
              dropdown={[
                {
                  label: "Approve selected",
                  onClick: approveSelectedStorePogs,
                  isDisabled: isAllSelected === false && selection.length === 0,
                },
                {
                  label: "Unapprove selected",
                  onClick: unapproveSelectedStorePogs,
                  isDisabled: isAllSelected === false && selection.length === 0,
                },
              ]}
            >
              Approvals
            </Button>

            <StorePogsExporter
              merchflowId={merchflowId}
              storePogsIds={selection}
              isDisabled={data === undefined}
              isAllSelected={isAllSelected}
            />
          </Flex>
        </Flex>

        <HorizontalDivider color={Color.lightGrayHover} />

        <Flex width="100%" justify="between" padding="10px" margin="5px 0 0 0">
          <Flex gap="10px">
            <ViewAsStoreTemplate merchflowId={merchflowId} />

            {viewMode === "grid" && (
              <Checkbox
                isChecked={isAllSelected}
                setIsChecked={setIsAllSelected}
                label="All Selected"
                isCheckboxLeft
              />
            )}
          </Flex>

          <Flex width="100%" justify="right" gap="10px">
            <SelectStorePogTrigger merchflowId={merchflowId} />

            <Select
              width="100px"
              title="Show as"
              isDisabled={data === undefined}
              value={viewMode}
              setValue={setViewMode}
              options={[
                {
                  label: "Grid",
                  value: "grid",
                },
                {
                  label: "List",
                  value: "list",
                },
              ]}
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex flexGrow column gap="10px">
        <PogsContainer>
          {isLoading && <PageStatus label="Loading stores..." icon="spinner" />}

          {!isLoading && data && viewMode === "list" && (
            <Flex flexGrow column gap="5px">
              <Scroller>
                <SubgridTable
                  page={page}
                  columns={getStoresAllTableColumns({
                    merchflowId,
                    setStoresAllHistory,
                    selection,
                    setSelection,
                    isAllSelected,
                    setIsAllSelected,
                    openBasePlanogramReviewModal,
                    openStorePlanogramReviewModal,
                    data: data.data,
                  })}
                  data={data.data}
                />
              </Scroller>
            </Flex>
          )}

          {!isLoading && data && viewMode === "grid" && (
            <Flex flexGrow column gap="5px">
              <Scroller>
                <Flex padding="20px" flexWrap="wrap" gap="25px">
                  {data.data.map((store, i) => {
                    const inProgress =
                      store.store_pog.status === "NOT_STARTED" ||
                      store.store_pog.status === "POGGERIZE_IN_PROGRESS" ||
                      store.store_pog.status === "POGGER_IN_PROGRESS" ||
                      store.store_pog.status === "UPDATE_IN_PROGRESS";

                    return (
                      <Flex key={i}>
                        <PogOverview
                          status={
                            (store.store_pog.status === "ERROR" && "ERROR") ||
                            (inProgress && "IN_PROGRESS") ||
                            (store.approved_store_pog_amount === 1 && "SUCCESS") ||
                            (store.approved_store_pog_amount > 1 && "WARNING") ||
                            "BORDER"
                          }
                          label={`Store: ${store.store}`}
                          badge={store.store_pog_amount > 1 && String(store.store_pog_amount)}
                          pog={store.store_pog.store_pog_data}
                          selectionId={store.store_pog.store_pog_id}
                          isAllSelected={isAllSelected}
                          selection={selection}
                          setSelection={setSelection}
                          warning={
                            store.store_pog.store_pog_data &&
                            isPogHavingRangeRecommendations(store.store_pog.store_pog_data) &&
                            "Range recommendation"
                          }
                          thumbnail={store.store_pog.thumbnail}
                          statusMessage={
                            (store.store_pog.status === "ERROR" && store.store_pog.error) ||
                            (inProgress && "Loading planogram...")
                          }
                        />
                      </Flex>
                    );
                  })}
                </Flex>
              </Scroller>
            </Flex>
          )}
        </PogsContainer>

        <Flex justify="between">
          {viewMode === "list" && <StoresListLegend />}

          {viewMode === "grid" && <StoresGridLegend />}

          <Pagination
            page={page}
            totalPages={data?.totalPages}
            setPage={setPage}
            isLoading={data === undefined}
          />
        </Flex>
      </Flex>
    </Page>
  );
};
